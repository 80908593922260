import "./styles.scss"

import React, { useState } from "react"
import Slider from "react-slick"
import { AnimatePresence, motion } from "framer-motion"

import ModalImage from "components/ModalImage"

const TABS = [
  {
    value: "outside",
    label: "wizualizacje zewnętrzne",
  },
  {
    value: "inside",
    label: "wnętrza",
  },
]

const slider = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 750,
  slidesToShow: 2,
  slidesToScroll: 1,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 840,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const data = {
  outside: [
    require("assets/images/gallery/outside/image-6.jpg").default,
    require("assets/images/gallery/outside/image-7.jpg").default,
    require("assets/images/gallery/outside/image-1.jpg").default,
    require("assets/images/gallery/outside/image-2.jpg").default,
    require("assets/images/gallery/outside/image-4.jpg").default,
    require("assets/images/gallery/outside/image-9.jpg").default,
    require("assets/images/gallery/outside/image-8.jpg").default,
    require("assets/images/gallery/outside/image-3.jpg").default,
    require("assets/images/gallery/outside/image-10.jpg").default,
    require("assets/images/gallery/outside/image-5.jpg").default,
    require("assets/images/gallery/outside/image-13.jpg").default,
    require("assets/images/gallery/outside/image-11.jpg").default,
  ],
  inside: [
    require("assets/images/gallery/inside/1.jpg").default,
    require("assets/images/gallery/inside/2.jpg").default,
    require("assets/images/gallery/inside/3.jpg").default,
    require("assets/images/gallery/inside/4.jpg").default,
    require("assets/images/gallery/inside/5.jpg").default,
    require("assets/images/gallery/inside/6.jpg").default,
  ],
}

const GallerySlider = () => {
  const [tab, setTab] = useState("outside")
  const [modalImage, setModalImage] = useState(null)

  return (
    <>
      <section className="home-gallery-slider">
        <div className="container-fluid">
          <h2>Galeria</h2>
          <div className="home-gallery-slider__tabs d-flex flex-column flex-sm-row align-items-center justify-content-sm-end">
            {TABS.map(item => (
              <button
                key={item.value}
                className={item.value === tab ? "active" : ""}
                type="button"
                onClick={() => setTab(item.value)}
              >
                {item.label}
              </button>
            ))}
          </div>
          <AnimatePresence exitBeforeEnter initial={false}>
            <motion.div
              key={tab}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: "tween", duration: 0.3 }}
            >
              <Slider {...slider}>
                {data[tab].map((item, index) => (
                  <div key={index}>
                    <button
                      className="home-gallery-slider__item"
                      onClick={() => setModalImage(item)}
                    >
                      <span
                        className="home-gallery-slider__image"
                        style={{ backgroundImage: `url('${item}')` }}
                      />
                    </button>
                  </div>
                ))}
              </Slider>
            </motion.div>
          </AnimatePresence>
        </div>
      </section>
      <ModalImage image={modalImage} setImage={setModalImage} />
    </>
  )
}

export default GallerySlider
