import "./styles.scss"

import React, { useState } from "react"

import Button from "components/Button"
import ModalImage from "components/ModalImage"

const Gallery = () => {
  const [image, setImage] = useState(null)
  const [showMore, setShowMore] = useState(false)

  return (
    <>
      <section className="investment-gallery">
        <div className="container-fluid">
          <h3>Galeria</h3>
          <div className="row">
            <div className="col-md-6">
              <GalleryItem
                image={
                  require("assets/images/gallery/outside/image-7.jpg").default
                }
                setImage={setImage}
              />
            </div>
            <div className="col-md-6">
              <GalleryItem
                image={
                  require("assets/images/gallery/outside/image-8.jpg").default
                }
                setImage={setImage}
              />
            </div>
          </div>
          <div className="row investment-gallery--row-small">
            <div className="col-md-4">
              <GalleryItem
                image={
                  require("assets/images/gallery/outside/image-5.jpg").default
                }
                setImage={setImage}
              />
            </div>
            <div className="col-md-4">
              <GalleryItem
                image={
                  require("assets/images/gallery/outside/image-3.jpg").default
                }
                setImage={setImage}
              />
            </div>
            <div className="col-md-4">
              <GalleryItem
                image={
                  require("assets/images/gallery/outside/image-10.jpg").default
                }
                setImage={setImage}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <GalleryItem
                image={
                  require("assets/images/gallery/outside/image-6.jpg").default
                }
                setImage={setImage}
              />
            </div>
            <div className="col-md-7">
              <GalleryItem
                image={
                  require("assets/images/gallery/outside/image-4.jpg").default
                }
                setImage={setImage}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <GalleryItem
                image={
                  require("assets/images/gallery/outside/image-1.jpg").default
                }
                setImage={setImage}
              />
            </div>
            <div className="col-md-6">
              <GalleryItem
                image={
                  require("assets/images/gallery/outside/image-2.jpg").default
                }
                setImage={setImage}
              />
            </div>
          </div>
          <div className="row investment-gallery--row-small">
            <div className="col-md-4">
              <GalleryItem
                image={
                  require("assets/images/gallery/outside/image-9.jpg").default
                }
                setImage={setImage}
              />
            </div>
            <div className="col-md-4">
              <GalleryItem
                image={
                  require("assets/images/gallery/outside/image-13.jpg").default
                }
                setImage={setImage}
              />
            </div>
            <div className="col-md-4">
              <GalleryItem
                image={
                  require("assets/images/gallery/outside/image-11.jpg").default
                }
                setImage={setImage}
              />
            </div>
          </div>
          {showMore ? (
            <>
              <div className="row">
                <div className="col-md-5">
                  <GalleryItem
                    image={
                      require("assets/images/gallery/inside/1.jpg").default
                    }
                    setImage={setImage}
                  />
                </div>
                <div className="col-md-7">
                  <GalleryItem
                    image={
                      require("assets/images/gallery/inside/2.jpg").default
                    }
                    setImage={setImage}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-7">
                  <GalleryItem
                    image={
                      require("assets/images/gallery/inside/3.jpg").default
                    }
                    setImage={setImage}
                  />
                </div>
                <div className="col-md-5">
                  <GalleryItem
                    image={
                      require("assets/images/gallery/inside/4.jpg").default
                    }
                    setImage={setImage}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <GalleryItem
                    image={
                      require("assets/images/gallery/inside/5.jpg").default
                    }
                    setImage={setImage}
                  />
                </div>
                <div className="col-md-7">
                  <GalleryItem
                    image={
                      require("assets/images/gallery/inside/6.jpg").default
                    }
                    setImage={setImage}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="investment-gallery__more d-flex justify-content-center">
              <Button onClick={() => setShowMore(true)}>Zobacz więcej</Button>
            </div>
          )}
        </div>
      </section>
      <ModalImage image={image} setImage={setImage} />
    </>
  )
}

const GalleryItem = ({ image, setImage }) => (
  <button className="investment-gallery__item" onClick={() => setImage(image)}>
    <span
      className="investment-gallery__image"
      style={{ backgroundImage: `url(${image})` }}
    />
  </button>
)

export default Gallery
