import "./styles.scss"

import Slider from "react-slick"
import React, { useState, useEffect } from "react"
import scrollToElement from "scroll-to-element"
// import { Typewriter } from "react-simple-typewriter"

const slider = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 2000,
  autoplay: true,
  autoplaySpeed: 8000,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
  useTransform: false,
}

const Hero = () => {
  const [position, setPosition] = useState(0)
  // const [key, setKey] = useState(0)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setPosition(-window?.scrollY / 5)
    })
  }, [])

  const data = [
    {
      image: require("assets/images/home-hero-bg-2.jpg").default,
      text: (
        <>
          Obniżamy ceny
          <br />
          mieszkań
        </>
      ),
      cta: {
        label: "Sprawdź ofertę",
      },
      font_size: "small",
    },
    {
      image: require("assets/images/home-hero-bg-3.jpg").default,
      text: (
        <>
          Przyjdź i zobacz
          <br />
          wymarzone mieszkanie
          <br />
          na żywo
        </>
      ),
      cta: {
        label: "Umów wizytę",
      },
      font_size: "very_small",
    },
  ]

  return (
    <section className="home-hero">
      <Slider {...slider}>
        {data?.map((item, index) => (
          <div key={index}>
            <div
              className="home-hero__content"
              style={{ backgroundImage: `url('${item?.image}')` }}
            >
              <div className="container-fluid">
                <div className="home-hero__wrapper">
                  <h1
                    style={{ transform: `translateY(${position}px)` }}
                    className={item?.font_size ? item.font_size : ""}
                  >
                    {item?.text}
                  </h1>
                  {item?.cta?.label && (
                    <button
                      className="button home-hero__scroll"
                      onClick={() =>
                        scrollToElement("#contact", { duration: 500 })
                      }
                    >
                      {item.cta.label}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  )
}

export default Hero
